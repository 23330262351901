import React, { useContext } from 'react';
import { languageOptions } from './languages';
import { LanguageContext } from './language';
import IconButton from '@mui/material/IconButton';
import {MDBIcon} from 'mdb-react-ui-kit';
import Tooltip from '@mui/material/Tooltip';

export default function LanguageSelector() 
{
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const handleLanguageChange = (e) => 
  {
    userLanguageChange(e.currentTarget.value);
    if(e.currentTarget.value === 'es')
    {
      document.title = 'Gabinete de Psicología Mª Asun Cortés';
    }
    else if(e.currentTarget.value === 'eu')
    {
      document.title = 'Mª Asun Cortés Psikologi Kabinetea';
    }
    else
    {
      document.title = 'Gabinete de Psicología Mª Asun Cortés';
    }
  }
  return (
    <>
      {Object.entries(languageOptions).map(([id, name]) => (
        <Tooltip title={name} arrow key={id}>
          <IconButton
            key={id} 
            value={id}
            onClick={handleLanguageChange}
          >
            <MDBIcon key={id}>
              <img src={`/img/iconos/${id}.webp`} key={id} height={30} width={30}/>
            </MDBIcon>
          </IconButton>
        </Tooltip>
      ))}
    </>
  );
};