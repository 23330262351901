import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {MDBIcon} from 'mdb-react-ui-kit';
import CopyButton from './CopyButton';
import { useForm } from "react-hook-form";
import BlogPopup from './BlogPopup';
import Text from './Text';
import Chip from '@mui/material/Chip';
import { useContext } from 'react';
import { LanguageContext } from './lang/language';
import CircularProgress from '@mui/material/CircularProgress';



export default function Contacto()
{
  const [feedback, setFeedback] = React.useState(false);
  const [received, setReceived] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [chipLabel, setChipLabel] = React.useState(false);
  const languageContext = useContext(LanguageContext);

  let styles =
  {
    padding: "0 !important;"
  }

  const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
  const onSubmit = data => 
    {
      setSending(true);
    
      fetch('api/Form', 
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(
        (response) => {
          setSending(false);
          setReceived(true);
          if(response.ok)
          {
            setFeedback(true);
            setChipLabel(languageContext.dictionary["contact-chip-true"]);
            reset();
          }
          else
          {
            setFeedback(false);
            setChipLabel(languageContext.dictionary["contact-chip-false"]);
          }
        });
    }

    const handleClick = () => 
    {

    };

    const handleDelete = () => 
    {
      setReceived(false);
      reset();
    };

  return (
    <div className='contacto'>
      <div className='contacto-first'>
        <div className='contactoInfo'>
          <div className='contactoInfoBack'></div>
            <div className='container'>
              <div className='fs-2 text-center'>
                <Text tid="contacto-info-title"></Text>
              </div>
              <div className='row contactoInfoItem'><div className='col contacto-col'  style={{styles}}>
                <MDBIcon className="me-3">
                    <img src='/img/whatsapp24.webp'/>
                </MDBIcon>              
                667 99 59 29
                <div>
                  <CopyButton texto='667 99 59 29'></CopyButton>
                </div>
              </div></div>
              <div className='row contactoInfoItem'><div className='col contacto-col'>
                <MDBIcon icon="phone" className="me-3" /> 94 320 86 00
                <div>
                  <CopyButton texto='94 320 86 00'></CopyButton>
                </div>
              </div></div>
              <div className='row contactoInfoItem'><div className='col contacto-col'>
                <MDBIcon icon="envelope" className="me-3" /> gpsimac@gmail.com
                <div>
                  <CopyButton texto='gpsimac@gmail.com'></CopyButton>
                </div>
              </div></div>
              <div className='row contactoInfoItem'><div className='col contacto-col'>
                <MDBIcon icon="home" className="me-2" /><Text tid="contacto-info-direc1"></Text> 5, 4º
                  20600 Eibar,
                  Gipuzkoa
                <div>
                  <CopyButton texto='Plaza Untzaga 5, 4º 20600 Eibar, Gipuzkoa'></CopyButton>
                </div>
              </div></div>
              <div className='row contactoInfoItem'><div className='col contacto-col'>
                <MDBIcon icon="home" className="me-2" />
                  Santiago Onaindia Kalea, 9, C 1 A, 48340 <Text tid="contacto-info-direc2"></Text>, Bizkaia
                <div>
                  <CopyButton texto='Santiago Onaindia Kalea, 9, C 1 A, 48340 Amorebieta-Etxano, Bizkaia'></CopyButton>
                </div>
              </div></div>
              <div className='row contactoInfoItem2'><div className='col'></div></div>
              <div className='row contactoInfoItem2'><div className='col'></div></div>
              <div className='row contactoInfoItem2'><div className='col'></div></div>
          </div>
        </div>
        <div className='contactoForm'>
          <div className='fs-2 text-center'>
            <Text tid="contacto-form-title"></Text>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)} className='position-relative contacto-innerForm'>
            <Form.Group className="mb-4" controlId="contactoNombre">
              <Form.Label><Text tid="contacto-form-nombre"></Text></Form.Label>
              <Form.Control 
                type="text" 
                {...register("nombre", { required: true })}
                isInvalid={errors.nombre}
              />
              <Form.Control.Feedback type="invalid">
                {errors.nombre?.type === 'required' && <p className='contanto-error'><Text tid="contacto-form-nombre-error"></Text></p>}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4" controlId="contactoApellidos">
              <Form.Label><Text tid="contacto-form-apellidos"></Text></Form.Label>
              <Form.Control 
                type="text" 
                {...register("apellidos", { required: true })}
                isInvalid={errors.apellidos}
              />
              <Form.Control.Feedback type="invalid">
                {errors.apellidos?.type === 'required' && <p className='contanto-error'><Text tid="contacto-form-apellidos-error"></Text></p>}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4" controlId="contactoEmail">
              <Form.Label><Text tid="contacto-form-email"></Text></Form.Label>
              <Form.Control 
                type="email" 
                {...register("correo", { required: true, minLength: 2 })}
                isInvalid={errors.correo}
                />
              <Form.Control.Feedback type="invalid">
                {errors.correo?.type === 'required' && <p className='contanto-error'><Text tid="contacto-form-email-error"></Text></p>}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4" controlId="contactoTelefono">
              <Form.Label><Text tid="contacto-form-tele"></Text></Form.Label>
              <Form.Control 
                type="text" 
                {...register("telefono")}
                isInvalid={errors.telefono}
                />
            </Form.Group>
            <Form.Group className="mb-4" controlId="contactoMotivo">
              <Form.Label><Text tid="contacto-form-consulta"></Text></Form.Label>
              <Form.Control 
                as="textarea" 
                rows={3} 
                {...register("motivo", { required: true })}
                isInvalid={errors.motivo}
                />
              <Form.Control.Feedback type="invalid">
                {errors.motivo?.type === 'required' && <p className='contanto-error'><Text tid="contacto-form-consulta-error"></Text></p>}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="" controlId="contactoAcepto">
              <Form.Check 
                type="checkbox" 
                {...register('acepto', { required: true })}
                isInvalid={errors.acepto}
                className='contacto-privacidad d-flex'
                label={
                  <span className='d-flex w-100'>
                    <Text tid="contacto-form-check-1"></Text>
                    {<BlogPopup terms black form title={<Text tid="footer-privacidad"></Text>} src='legal/privacidad.html'></BlogPopup>}
                    <Text tid="contacto-form-check-2"></Text>
                  </span>
                }
              />
              {errors.acepto && (
                <Form.Text className="text-danger">
                  {<p className='contanto-error'><Text tid="contacto-form-check-error"></Text></p>}
                </Form.Text>
              )}
            </Form.Group>
            <div className='d-flex'>
              <div className='formChipContainer'>
                <div className='contacto-piece-box'>
                  <Chip
                    label={chipLabel}
                    variant="outlined"
                    onClick={handleClick}
                    onDelete={handleDelete}
                    style={{visibility: received ?  'visible' : 'hidden', 
                      color: feedback ? 'green' : 'red',
                      borderColor: feedback ? 'green' : 'red'
                      }}
                    className='formChip'
                  />
                </div>
              </div>
              <div className='contacto-piece-box'>
                <CircularProgress className={sending ? "contacto-progress" : "d-none"}/>
              </div>
              <Button variant="primary" type="submit" className='contacto-submit'>
                <Text tid="contacto-form-send"></Text>
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <div className='d-flex justify-content-center m-5'>
        <div className='fs-2 text-center'>
          <Text tid="contacto-mapa-title"></Text>
          <div className='fs-4 mb-3'>
            <Text tid="contacto-mapa-subtitle1"></Text>:
          </div>
          <div className='contactoMapa'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1729.838269820435!2d-2.474555137218845!3d43.183693023731166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd4e2a88fcf1b267%3A0x612dbaeec10f1284!2sGabinete%20De%20Psicolog%C3%ADa%20M%C2%AA%20Asun%20Cort%C3%A9s!5e0!3m2!1sen!2ses!4v1675689563338!5m2!1sen!2ses" className='map' style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <hr className='divider w-50'></hr>
          <div className='fs-4 mb-3'>
            <Text tid="contacto-mapa-subtitle2"></Text>:
          </div>
          <div className='contactoMapa'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2907.6824255421407!2d-2.732982933107612!3d43.21614985625534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd4e352b459013d3%3A0x8912fb06c3cb7231!2sSantiago%20Onaindia%20Kalea%2C%209%2C%20c%201%20a%2C%2048340%20Amorebieta-Etxano%2C%20Bizkaia!5e0!3m2!1sen!2ses!4v1686584696137!5m2!1sen!2ses" className='map' style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}
