import React from 'react';
import Text from './Text';
import ServiciosItem from './ServiciosItem';


export default function Servicios()
{
  return (
    <div className='servicios pt-5'>
      <div className='servicios-features'>
        <div className='servicios-feature-wrapper'>
          <div className='servicios-feature'>
            <div className='servicios-feature-content'>
              <div className='servicios-feature-text'>
                <h3 className='text-center servicios-feature-title'><u><Text tid="servicios-general-sesiones-title"></Text></u></h3>
                <Text tid="servicios-general-sesiones"></Text>
              </div>
              <div className='servicios-feature-display'>
                <img 
                  className='servicios-feature-img'
                  src="/img/iconos/servicios/general/sesiones.webp"
                ></img>
              </div>
            </div>
          </div>  
        </div>
        <div className='servicios-feature-wrapper2'>
          <div className='servicios-feature'>
            <div className='servicios-feature-content'>
              <div className='servicios-feature-display'>
                <img 
                  className='servicios-feature-img'
                  src="/img/iconos/servicios/general/modo.webp"
                ></img>
              </div>
              <div className='servicios-feature-text'>
                <h3 className='text-center'><u><Text tid="servicios-general-modo-title"></Text></u></h3>
                <Text tid="servicios-general-modo"></Text>
              </div>
            </div>
          </div>  
        </div>
        <div className='servicios-feature-wrapper'>
          <div className='servicios-feature'>
            <div className='servicios-feature-content'>
              <div className='servicios-feature-text'>
                <h3 className='text-center'><u><Text tid="servicios-general-idioma-title"></Text></u></h3>
                <Text tid="servicios-general-idioma"></Text>
              </div>
              <div className='servicios-feature-display'>
                <img 
                  className='servicios-feature-img'
                  src="/img/iconos/servicios/general/idioma.webp"
                ></img>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <hr className='divider w-75'></hr>
      <div className='servicios-title'>
        <Text tid="home-terapias"></Text>
      </div>
      <div className='servicios-main mb-5'>
        <div className='servicios-cate-wrap'>
          <div className='servicios-cate-name'><Text tid="nav-tier2-infantil"></Text></div>
          <div className='servicios-cate servicios-cate-infantil'>
            <ServiciosItem nombre='servicios-infantil-fracaso_escolar-title' path='infantil/fracaso-escolar'></ServiciosItem>
            <ServiciosItem nombre='servicios-infantil-problemas_de_conducta-title' path='infantil/problemas-de-conducta'></ServiciosItem>
            <ServiciosItem nombre='servicios-infantil-asesoramiento_a_padres-title' path='infantil/asesoramiento-a-padres'></ServiciosItem>
            <ServiciosItem nombre='servicios-infantil-tdah-short' path='infantil/tdah'></ServiciosItem>
            <ServiciosItem nombre='servicios-infantil-logopedia-title' path='infantil/logopedia'></ServiciosItem>
            <ServiciosItem nombre='servicios-infantil-refuerzo_educativo-title' path='infantil/refuerzo-educativo'></ServiciosItem>
          </div>
        </div>
        <div className='servicios-cate-wrap'>
          <div className='servicios-cate-name'><Text tid="nav-tier2-pareja"></Text></div>
          <div className='servicios-cate-pare'>
            <ServiciosItem nombre='servicios-pareja-main-title' path='pareja'></ServiciosItem>
          </div>
        </div>
        <div className='servicios-cate-wrap'>
          <div className='servicios-cate-name'><Text tid="nav-tier2-adultos"></Text></div>
          <div className='servicios-cate'>
            <ServiciosItem nombre='servicios-adultos-ansiedad-title' path='adultos/ansiedad'></ServiciosItem>
            <ServiciosItem nombre='servicios-adultos-estres-title' path='adultos/estres'></ServiciosItem>
            <ServiciosItem nombre='servicios-adultos-depresion-title' path='adultos/depresion'></ServiciosItem>
            <ServiciosItem nombre='servicios-adultos-adicciones_psicologicas-title' path='adultos/adicciones-psicologicas'></ServiciosItem>
            <ServiciosItem nombre='servicios-adultos-trastornos_alimentarios-title' path='adultos/trastornos-alimentarios'></ServiciosItem>
            <ServiciosItem nombre='servicios-adultos-baja_autoestima-title' path='adultos/baja-autoestima'></ServiciosItem>
            <ServiciosItem className='servicios-item-last' nombre='servicios-adultos-obsesiones-title' path='adultos/obsesiones'></ServiciosItem>
          </div>
        </div>
      </div>
    </div>
  )
}
