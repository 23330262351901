import React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Text from './Text';


export default function CopyButton(props)
{
    const [openTooltip, setOpenTooltipValue] = React.useState(false);

  return (
    <Tooltip 
      title={openTooltip ? <Text tid="copiado"></Text> : <Text tid="copiar"></Text>} 
      arrow 
      placement="left"
      onClose={() => setOpenTooltipValue(false)}
      enterTouchDelay='100'
    >
      <IconButton
        onClick={() => 
        {
          navigator.clipboard.writeText(props.texto);
          setOpenTooltipValue(true);
        }}
      >
        <ContentCopyIcon/>
      </IconButton>
    </Tooltip>
    );
}
