import React from 'react';
import {
  MDBFooter,
  MDBIcon,
  MDBBtn
} from 'mdb-react-ui-kit';
import BlogPopup from './BlogPopup';
import Text from './Text';

export default function MyFooter(props)
{

  return (
     <MDBFooter className='text-center p-3 d-flex flex-column' color='white' bgColor='dark'>
        <section className='mb-2'>
          <MDBBtn outline color="light" floating className='m-1' href='https://www.facebook.com/gpsimac/' target="_blank" role='button'>
            <MDBIcon fab icon='facebook-f' />
          </MDBBtn>

          <MDBBtn outline color="light" floating className='m-1' href='https://www.instagram.com/mariasuncortes/' target="_blank" role='button'>
            <MDBIcon fab icon='instagram' />
          </MDBBtn>

        </section>
        <div className="text-white-50"><Text tid="footer-autor"></Text></div>
        <div className="text-white-50">© 2023 <Text tid="nav-brand1"></Text><Text tid="nav-brand2"></Text></div>
        <div className='footer-docs'>
          <BlogPopup terms title={<Text tid="footer-avisoLegal"></Text>} src='legal/avisolegal.html'></BlogPopup>
           | 
          <BlogPopup terms title={<Text tid="footer-privacidad"></Text>} src='legal/privacidad.html'></BlogPopup>
           | 
          <BlogPopup terms title={<Text tid="footer-cookies"></Text>} src='legal/cookies.html'></BlogPopup>
           | 
          <BlogPopup terms title={<Text tid="footer-codigo"></Text>} src='legal/codigoetico.html'></BlogPopup>
        </div>
    </MDBFooter>
  )
}
