import React from 'react';
import Text from './Text';


export default function ServiciosItem(props)
{
  return (
      <div 
        className='servicios-item'
        onClick = {() => window.open(`servicios/${props.path}`,'mywindow')}
        style={{cursor: "pointer"}}
      >
        <div className='servicios-item-icon-wrapper'>
          <img className='servicios-item-icon' src={`/img/iconos/servicios/${props.path}.webp`}></img>
        </div>
        <div className='servicios-item-name'>
          <Text tid={props.nombre}></Text>
        </div>
      </div>
  )
}
