import React from 'react';
import BlogPopup from './BlogPopup';

export default function BlogPreview(props)
{
  let [info, setInfo] = React.useState({});

  const getData = () =>
  {
    let newInfo = 
    {
      date: props.post.published,
      day: props.post.published.split('T')[0].split('-')[2],
      month: props.post.published.split('T')[0].split('-')[1],
      year: props.post.published.split('T')[0].split('-')[0],
      title: props.post.title,
      content: props.post.content,
      author: props.post.author.displayName
    }
    setInfo(newInfo);
  }

  React.useEffect(() => 
  {
    getData();
  }, [props]);


  return (
    <div className='blog-preview'>
      <div className='blog-preview-top'></div>
      <div className='blog-preview-header'>
        <div className='blog-preview-date'>
          <div>{info.day}</div>
          <div>{info.month}</div>
          <div>{info.year}</div>
        </div>
        <div className='blog-preview-title'>{info.title}</div>
      </div>
      <hr className='blog-divider w-75'></hr>
      <div className='blog-preview-content' dangerouslySetInnerHTML={{ __html: info?.content }}>{info.description}</div>
      <div className='blog-preview-extra'>
        <BlogPopup info={info}></BlogPopup>  
      </div>
    </div>
  )
}
