import React, { useEffect, useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {useLocation} from 'react-router-dom';
import LanguageSelector from './lang/LanguageSelector';
import Text from './Text';
import { useMediaQuery } from 'react-responsive';
import Accordion from 'react-bootstrap/Accordion';


export default function MyNav()
{
  const isTablet = useMediaQuery({ query: '(max-width: 1000px)' })
  const isLaptop = useMediaQuery({ query: '(max-width: 1440px)' })

  const [serviciosToggle, setServiciosToggle]=React.useState(false);
  const [infantilToggle, setInfantilToggle]=React.useState(false);
  const [adultosToggle, setAdultosToggle]=React.useState(false);
  
  const location = useLocation().pathname;

  const setCurrent = () => 
  {
    if(location.includes("servicios"))
    {
      setServiciosToggle(true);
    }
    if(location.includes("infantil"))
    {
      setInfantilToggle(true);
    }
    if(location.includes("adultos"))
    {
      setAdultosToggle(true);
    }
  }

  useEffect(() => 
  {
    setCurrent();
  }, [])

  function toggleServicios(id)
  {
    if(location.includes("servicios"))
    {
      setServiciosToggle(true);
    }
    else
    {
      if(id === "nav-item-servicios"
        || id === "nav-items-servicios"
        || id === "nav-items-infantil"
        || id === "nav-items-adultos")
      {
        setServiciosToggle(true);
      }
      else if(id === "out")
      {
        setServiciosToggle(false);
      }
    }

  }

  function toggleInfantil(id)
  {
    if(location.includes("adultos"))
    {
      setAdultosToggle(false);
    }
    if(location.includes("infantil"))
    {
      setInfantilToggle(true);
    }
    else
    {
      if(id === "nav-item-infantil"
        || id === "nav-items-infantil")
      {
        setInfantilToggle(true);
        toggleServicios(id);
      }
      else if(id === "out")
      {
        setInfantilToggle(false);
        if(location.includes("adultos"))
        {
          setAdultosToggle(true);
        }
      }
      else if(id === "out-child")
      {
        setInfantilToggle(false);
        toggleServicios("out");
        if(location.includes("adultos"))
        {
          setAdultosToggle(true);
        }
      }
    }
  }

  function toggleAdultos(id)
  {
    if(location.includes("infantil"))
    {
      setInfantilToggle(false);
    }
    if(location.includes("adultos"))
    {
      setAdultosToggle(true);
    }
    else
    {
      if(id === "nav-item-adultos"
        || id === "nav-items-adultos")
      {
        setAdultosToggle(true);
        toggleServicios(id);
      }
      else if(id === "out")
      {
        setAdultosToggle(false);
        if(location.includes("infantil"))
        {
          setInfantilToggle(true);
        }
      }
      else if(id === "out-child")
      {
        setAdultosToggle(false);
        toggleServicios("out");
        if(location.includes("infantil"))
        {
          setInfantilToggle(true);
        }
      }
    }
  }

  return (
    <>
      {
        isTablet
        ?
        <Navbar bg="light" expand={false} sticky="top">  
          <Container fluid>  
            <Navbar.Brand href="#">
                <img
                  src="/favicon-32x32.png"
                  width="32"
                  height="32"
                  className="d-inline-block align-top"
                  alt="Logo"
                />
               <div className='nav-title'>
                <Text tid="nav-brand1"></Text>
                <br></br>
                <Text tid="nav-brand2"></Text>
              </div>
              </Navbar.Brand>  
            <Navbar.Toggle aria-controls="offcanvasNavbar" />  
            <Navbar.Offcanvas  
              id="offcanvasNavbar"  
              aria-labelledby="offcanvasNavbarLabel"  
              placement="end"  
            >  
              <Offcanvas.Header closeButton>  
              </Offcanvas.Header>  
              <Offcanvas.Body>  
                <Nav className="justify-content-end flex-grow-1 pe-3">  
                  <Nav.Link href="/"><Text tid="nav-tier1-inicio"></Text></Nav.Link> 
                  <Accordion defaultActiveKey="2">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className='nav-link collapsed'><Text tid="nav-mobile-infantil"></Text></Accordion.Header>
                      <Accordion.Body>
                        <Nav.Link className='link-secondary' href="/servicios/infantil"><Text tid="servicios-infantil-trastornos_infantiles-title"></Text></Nav.Link>
                        <Nav.Link className='link-secondary' href="/servicios/infantil/fracaso-escolar"><Text tid="servicios-infantil-fracaso_escolar-title"></Text></Nav.Link>  
                        <Nav.Link className='link-secondary' href="/servicios/infantil/problemas-de-conducta"><Text tid="servicios-infantil-problemas_de_conducta-title"></Text></Nav.Link>  
                        <Nav.Link className='link-secondary' href="/servicios/infantil/asesoramiento-a-padres"><Text tid="servicios-infantil-asesoramiento_a_padres-title"></Text></Nav.Link>  
                        <Nav.Link className='link-secondary' href="/servicios/infantil/tdah"><Text tid="servicios-infantil-tdah-short"></Text></Nav.Link>  
                        <Nav.Link className='link-secondary' href="/servicios/infantil/logopedia"><Text tid="servicios-infantil-logopedia-title"></Text></Nav.Link>  
                        <Nav.Link className='link-secondary nav-link-no-bb' href="/servicios/infantil/refuerzo-educativo"><Text tid="servicios-infantil-refuerzo_educativo-title"></Text></Nav.Link>  
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header  className='nav-link'><Text tid="nav-mobile-adultos"></Text></Accordion.Header>
                      <Accordion.Body>
                        <Nav.Link className='link-secondary' href="/servicios/adultos"><Text tid="servicios-adultos-trastornos-title"></Text></Nav.Link>  
                        <Nav.Link className='link-secondary' href="/servicios/adultos/ansiedad"><Text tid="servicios-adultos-ansiedad-title"></Text></Nav.Link>  
                        <Nav.Link className='link-secondary' href="/servicios/adultos/estres"><Text tid="servicios-adultos-estres-title"></Text></Nav.Link>  
                        <Nav.Link className='link-secondary' href="/servicios/adultos/depresion"><Text tid="servicios-adultos-depresion-title"></Text></Nav.Link>  
                        <Nav.Link className='link-secondary' href="/servicios/adultos/adicciones_psicologicas"><Text tid="servicios-adultos-adicciones_psicologicas-title"></Text></Nav.Link>  
                        <Nav.Link className='link-secondary' href="/servicios/adultos/trastornos_alimentarios"><Text tid="servicios-adultos-trastornos_alimentarios-title"></Text></Nav.Link>  
                        <Nav.Link className='link-secondary' href="/servicios/adultos/baja_autoestima"><Text tid="servicios-adultos-baja_autoestima-title"></Text></Nav.Link>  
                        <Nav.Link className='link-secondary nav-link-no-bb' href="/servicios/adultos/obsesiones"><Text tid="servicios-adultos-obsesiones-title"></Text></Nav.Link>  
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Nav.Link href="/servicios/pareja"><Text tid="nav-mobile-pareja"></Text></Nav.Link>  
                  <Nav.Link href="/quienes-somos"><Text tid="nav-tier1-quienes"></Text></Nav.Link> 
                  <Nav.Link className='nav-link-no-bb' href="/contacto"><Text tid="nav-tier1-contacto"></Text></Nav.Link>  
                  <div className='d-flex justify-content-end'>
                    <LanguageSelector></LanguageSelector>  
                  </div>
                </Nav>   
              </Offcanvas.Body>  
            </Navbar.Offcanvas>  
          </Container>  
        </Navbar>  
          :
        <>
        <Navbar
        variant="light"
        expand="lg"
        className='tier1-navbar'
        >
        <Container fluid className="tier1-navbar-content"> 
          <Navbar.Brand href="/" className='text-dark nav-brand'>
            <img
              src="/favicon.ico"
              className="nav-logo"
              alt="Logo"
            />
            <div className='nav-title'>
              <Text tid="nav-brand1"></Text>
              {isLaptop ? <br></br> : ''}
              <Text tid="nav-brand2"></Text>
            </div>
            
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="h-100 justify-content-evenly align-items-end" id="basic-navbar-nav">
            <Nav className="justify-content-evenly h-100" activeKey={location}>
              <Nav.Link href="/" className="tier1-nav-link">
                <div className='tier1-nav-link-in'>
                  <Text tid="nav-tier1-inicio"></Text>
                <div className='tier1-nav-link-footer'></div></div>
              </Nav.Link>
              <Nav.Link
                href="/servicios" 
                className={location.includes("servicios") ? "tier1-nav-link tier1-nav-link-active" : "tier1-nav-link"}
                id="nav-item-servicios"
                onMouseEnter={(event) => {toggleServicios(event.target.id)}}
                onMouseLeave={() => {toggleServicios("out")}}
              >
                <div 
                  className='tier1-nav-link-in'
                  id="nav-item-servicios"
                  >
                  <Text tid="nav-tier1-terapia"></Text><div className='tier1-nav-link-footer'></div>
                </div>    
              </Nav.Link>
              <Nav.Link href="/quienes-somos" className="tier1-nav-link">
                <div className='tier1-nav-link-in'><Text tid="nav-tier1-quienes"></Text><div className='tier1-nav-link-footer'></div></div>
              </Nav.Link>
              <Nav.Link href="/contacto" className="tier1-nav-link">
                <div className='tier1-nav-link-in'><Text tid="nav-tier1-contacto"></Text><div className='tier1-nav-link-footer'></div></div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Container className='d-flex align-items-center justify-content-center'> 
            <LanguageSelector></LanguageSelector>  
          </Container>
        </Container>
      </Navbar>
      <Navbar 
        id="nav-items-servicios"
        expand="md" 
        className={serviciosToggle ? "m-auto tier2-shown p-0" : "tier2-hidden p-0"}
        onMouseEnter={() => {toggleServicios("nav-items-servicios")}}
        onMouseLeave={() => {toggleServicios("out")}}
        >
        <div className="d-flex justify-content-between container-sm">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto justify-content-evenly" activeKey={location}>
              <Nav.Link
                href="/servicios/infantil"
                className={location.includes("infantil") ? "tier2-nav-link-active tier1-nav-link" : "tier1-nav-link"}
                id="nav-item-infantil"
                onMouseEnter={() => {toggleInfantil("nav-item-infantil")}}
                onMouseLeave={() => {toggleInfantil("out")}}
              >
                <div className={serviciosToggle ? "tier1-nav-link-in" : "tier1-hidden"}><Text tid="nav-tier2-infantil"></Text><div className='tier1-nav-link-footer'></div></div>
              </Nav.Link>
              <Nav.Link
                href="/servicios/adultos"
                className={location.includes("adultos") ? "tier2-nav-link-active tier1-nav-link" : "tier1-nav-link"}
                id="nav-item-adultos"
                onMouseEnter={() => {toggleAdultos("nav-item-adultos")}}
                onMouseLeave={() => {toggleAdultos("out")}}
              >
                <div className={serviciosToggle ? "tier1-nav-link-in" : "tier1-hidden"}><Text tid="nav-tier2-adultos"></Text><div className='tier1-nav-link-footer'></div></div>
              </Nav.Link>
              <Nav.Link href="/servicios/pareja" className="tier1-nav-link">
                <div className={serviciosToggle ? "tier1-nav-link-in" : "tier1-hidden"}><Text tid="nav-tier2-pareja"></Text><div className='tier1-nav-link-footer'></div></div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <Navbar
        id="nav-items-infantil"
        variant='secondary'
        expand="md" 
        className={infantilToggle ? "m-auto tier3-shown p-0" : "tier2-hidden p-0"}
        onMouseEnter={() => {toggleInfantil("nav-items-infantil")}}
        onMouseLeave={() => {toggleInfantil("out-child")}}
        >
        <div className="d-flex justify-content-between container-sm h-100">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className='h-100 overflow-hidden'>
            <Nav className="m-auto justify-content-evenly h-100 overflow-hidden" activeKey={location}>
              <Nav.Link href="/servicios/infantil/fracaso-escolar" className="tier1-nav-link ">
                <div className={infantilToggle ? "tier1-nav-link-in" : "tier1-hidden"}><Text tid="servicios-infantil-fracaso_escolar-title"></Text><div className='tier1-nav-link-footer'></div></div>
              </Nav.Link>
              <Nav.Link href="/servicios/infantil/problemas-de-conducta" className="tier1-nav-link">
                <div className={infantilToggle ? "tier1-nav-link-in" : "tier1-hidden"}><Text tid="servicios-infantil-problemas_de_conducta-title"></Text><div className='tier1-nav-link-footer'></div></div>
              </Nav.Link>
              <Nav.Link href="/servicios/infantil/asesoramiento-a-padres" className="tier1-nav-link">
                <div className={infantilToggle ? "tier1-nav-link-in" : "tier1-hidden"}><Text tid="servicios-infantil-asesoramiento_a_padres-title"></Text><div className='tier1-nav-link-footer'></div></div>
              </Nav.Link>
              <Nav.Link href="/servicios/infantil/tdah" className="tier1-nav-link">
                <div className={infantilToggle ? "tier1-nav-link-in" : "tier1-hidden"}><Text tid="servicios-infantil-tdah-short"></Text><div className='tier1-nav-link-footer'></div></div>
              </Nav.Link>
              <Nav.Link href="/servicios/infantil/logopedia" className="tier1-nav-link">
                <div className={infantilToggle ? "tier1-nav-link-in" : "tier1-hidden"}><Text tid="servicios-infantil-logopedia-title"></Text><div className='tier1-nav-link-footer'></div></div>
              </Nav.Link>
              <Nav.Link href="/servicios/infantil/refuerzo-educativo" className="tier1-nav-link">
                <div className={infantilToggle ? "tier1-nav-link-in" : "tier1-hidden"}><Text tid="servicios-infantil-refuerzo_educativo-title"></Text><div className='tier1-nav-link-footer'></div></div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <Navbar
        id="nav-items-adultos"
        expand="md" 
        className={adultosToggle ? "m-auto tier3-shown p-0" : "tier2-hidden p-0"}
        onMouseEnter={() => {toggleAdultos("nav-items-adultos")}}
        onMouseLeave={() => {toggleAdultos("out-child")}}
        >
        <div className="d-flex justify-content-between container-sm h-100">
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="basic-navbar-nav" className='h-100'>
            <Nav className="m-auto justify-content-evenly h-100" activeKey={location}>
              <Nav.Link href="/servicios/adultos/ansiedad" className="tier1-nav-link">
                <div className={adultosToggle ? "tier1-nav-link-in" : "tier1-hidden"}><Text tid="servicios-adultos-ansiedad-title"></Text><div className='tier1-nav-link-footer'></div></div>
              </Nav.Link>
              <Nav.Link href="/servicios/adultos/estres" className="tier1-nav-link">
                <div className={adultosToggle ? "tier1-nav-link-in" : "tier1-hidden"}><Text tid="servicios-adultos-estres-title"></Text><div className='tier1-nav-link-footer'></div></div>
              </Nav.Link>
              <Nav.Link href="/servicios/adultos/depresion" className="tier1-nav-link">
                <div className={adultosToggle ? "tier1-nav-link-in" : "tier1-hidden"}><Text tid="servicios-adultos-depresion-title"></Text><div className='tier1-nav-link-footer'></div></div>
              </Nav.Link>
              <Nav.Link href="/servicios/adultos/adicciones-psicologicas" className="tier1-nav-link">
                <div className={adultosToggle ? "tier1-nav-link-in" : "tier1-hidden"}><Text tid="servicios-adultos-adicciones_psicologicas-title"></Text><div className='tier1-nav-link-footer'></div></div>
              </Nav.Link>
              <Nav.Link href="/servicios/adultos/trastornos-alimentarios" className="tier1-nav-link">
                <div className={adultosToggle ? "tier1-nav-link-in" : "tier1-hidden"}><Text tid="servicios-adultos-trastornos_alimentarios-title"></Text><div className='tier1-nav-link-footer'></div></div>
              </Nav.Link>
              <Nav.Link href="/servicios/adultos/baja-autoestima" className="tier1-nav-link">
                <div className={adultosToggle ? "tier1-nav-link-in" : "tier1-hidden"}><Text tid="servicios-adultos-baja_autoestima-title"></Text><div className='tier1-nav-link-footer'></div></div>
              </Nav.Link>
              <Nav.Link href="/servicios/adultos/obsesiones" className="tier1-nav-link">
                <div className='tier1-nav-link-in'><Text tid="servicios-adultos-obsesiones-title"></Text><div className='tier1-nav-link-footer'></div></div>
              </Nav.Link>
              </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>    
      </>
      }
    </>
  )
}