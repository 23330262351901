import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from 'react-bootstrap/Button';
import { useMediaQuery } from 'react-responsive';

export default function BlogPopup(props)
{
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let[htmlFileString, setHtmlFileString] = useState();

  async function fetchHtml() {
    setHtmlFileString(await (await fetch(props.src)).text());
  }
  useEffect(() => {
    fetchHtml();
  }, []);

  const getLinkClass = () =>
  {
    let ret = '';
    if(props.black) 
    {
        ret += 'link-primary footer-popup ';
    }
    else
    {
      ret += 'text-white footer-popup ';
    }
    if(props.form)
    {
      ret += 'footer-popup-form ';
    }
    return ret;
  }


  return (
    <div>
      {
        props.terms ? 
          <a 
            className={getLinkClass()}
            style={{cursor: 'pointer'}} 
            onClick={handleOpen}>{props.title}</a>
          :
          /*<Button size='small' variant="outlined" onClick={handleOpen}>Seguir leyendo...</Button>*/
          <Button variant="secondary" size='sm' onClick={handleOpen}>Seguir leyendo...</Button>
      }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
        {
          props.terms ? 
          <Box className='blog-popup blog-popup-terms'>
            <div dangerouslySetInnerHTML={{ __html: htmlFileString }}></div>
          </Box>
          :
          <Box className='blog-popup blog-popup-blog'>
            <div className='blog-popup-header'>
              {
                  isMobile ? 
                  <>
                    <div className='blog-popup-title'>
                      {props.info?.title}
                    </div>
                    <div>
                      Fecha de publicación: {props.info?.day}/{props.info?.month}/{props.info?.year}
                    </div>
                    <div>
                      Autor: {props.info?.author}
                    </div>
                    <hr className='blog-divider'></hr>
                    </>
                  :
                  <>
                    <div>
                      Fecha de publicación: {props.info?.day}/{props.info?.month}/{props.info?.year}
                    </div>
                    <div className='blog-popup-title'>
                      {props.info?.title}
                    </div>
                    <div>
                      Autor: {props.info?.author}
                    </div>
                  </>
              }

            </div>
            <div dangerouslySetInnerHTML={{ __html: props.info?.content }}></div>    
          </Box>
        }
        <Button className='blog-button' onClick={handleClose}>SALIR</Button>
        </>
      </Modal>
    </div>
  )
}
