import React from 'react';
import Button from '@mui/material/Button';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

export default function Error()
{
  return (
    <div>

      <div className='d-flex flex-column justify-content-center align-items-center'>
        <div className='servicios-title'>
          NO HAY NADA AQUI...
        </div>
        <img 
          className='error-img'
          src='img/error.webp'
        ></img>  
        <Button 
            variant="outlined" 
            endIcon={<KeyboardReturnIcon />}
            onClick = {() => window.location.href='/'}
            >
          Volver a inicio
        </Button>
      </div>  
    </div>
  )
}
