import React, { useContext } from 'react';
import { LanguageContext } from './lang/language';

export default function Text(props)
{
  const languageContext = useContext(LanguageContext);
  if(languageContext.dictionary[props.tid])
  {
    if(props.list)
    {
      return languageContext.dictionary[props.tid].map((item, index) =>
        <li key={index}>{item}</li>
      );
    }
    else if(props.para)
    {
      return languageContext.dictionary[props.tid].map((item, index) =>
        {
          return (
            <p 
              key={index}
              dangerouslySetInnerHTML={{__html: item}}
              >
              </p>
            )
        }
      );
    }
    else if(props.lower)
    {
      return languageContext.dictionary[props.tid].toLowerCase();
    }
    return languageContext.dictionary[props.tid];
  }
  return "";
}
