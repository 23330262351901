import React, { useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import {MDBIcon} from 'mdb-react-ui-kit';
import Text from './Text';


export default function Whatsapp()
{

  return (
    <div className='whatsapp'>
      <IconButton
        className = 'whatsapp-icon'
        onClick={() => {window.open('https://wa.me/667995929', '_blank', 'noreferrer');}}
        style={{width:'100%'}}
      >
        <MDBIcon className="me-3 whatsapp-icon">
          <img className='whatsapp-icon' src='/img/iconos/whatsappDongle.webp' loading='lazy'/>
        </MDBIcon>
        <div className='whatsapp-text'><Text tid="whatsapp"></Text> </div>
      </IconButton>
    </div>    
  )
}
