import React, { Component } from 'react';
import {Route,Routes} from 'react-router-dom';
import Home from './components/Home';
import Contacto from './components/Contacto';
import Blog from './components/Blog';
import Servicios from './components/Servicios';
import Servicio from './components/Servicio';
import ServicioCategoria from './components/Servicio';
import QuienesSomos from './components/QuienesSomos';
import Error from './components/Error';
import MyNav from './components/MyNav';
import MyFooter from './components/MyFooter';
import { LanguageProvider } from './components/lang/language'
import Whatsapp from './components/Whatsapp';


export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <LanguageProvider>
        <MyNav/>
        <div className='content'>
          <Routes>
              <Route index element={<Home />} />
              <Route path='/index.html' element={<Home />} />
              <Route path='/servicios'>
                <Route index element={<Servicios />} />
                <Route path='infantil'>
                  <Route index element={<ServicioCategoria categoria="infantil" tema="trastornos_infantiles"/>} />
                  <Route path='fracaso-escolar' element={<Servicio categoria="infantil" tema="fracaso_escolar" left={true}/>} />
                  <Route path='problemas-de-conducta' element={<Servicio categoria="infantil" tema="problemas_de_conducta" left={true}/>} />
                  <Route path='asesoramiento-a-padres' element={<Servicio categoria="infantil" tema="asesoramiento_a_padres"/>} />
                  <Route path='tdah' element={<Servicio categoria="infantil" tema="tdah"/>} />
                  <Route path='logopedia' element={<Servicio categoria="infantil" tema="logopedia" left={true}/>} />
                  <Route path='refuerzo-educativo' element={<Servicio categoria="infantil" tema="refuerzo_educativo"/>} />
                </Route>
                <Route path='adultos'>
                  <Route index element={<ServicioCategoria categoria="adultos" tema="trastornos" left={true}/>} />
                  <Route path='ansiedad' element={<Servicio categoria="adultos" tema="ansiedad"/>} />
                  <Route path='estres' element={<Servicio categoria="adultos" tema="estres" left={true}/>} />
                  <Route path='depresion' element={<Servicio categoria="adultos" tema="depresion"/>} />
                  <Route path='adicciones-psicologicas' element={<Servicio categoria="adultos" tema="adicciones_psicologicas" left={true}/>} />
                  <Route path='trastornos-alimentarios' element={<Servicio categoria="adultos" tema="trastornos_alimentarios"/>} />
                  <Route path='baja-autoestima' element={<Servicio categoria="adultos" tema="baja_autoestima" left={true}/>} />
                  <Route path='obsesiones' element={<Servicio categoria="adultos" tema="obsesiones" left={true}/>} />
                </Route>
                <Route path='pareja' element={<ServicioCategoria categoria="pareja" tema="main"/>} />
              </Route>
              <Route path='/blog' element={<Blog />} />
              <Route path='/quienes-somos' element={<QuienesSomos />} />
              <Route path='/contacto' element={<Contacto />} />
              <Route path='*' element={<Error />} />
          </Routes>
        </div>
        <MyFooter/>
        <Whatsapp></Whatsapp>
      </LanguageProvider>

    );
  }
}
