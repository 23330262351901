import React, { useEffect } from 'react';
import Perfil from './Perfil';
import Text from './Text';

export default function QuienesSomos()
{

  return (
    <div className='quienes-somos'>
      <h1 className='m-auto mt-4 text-center'><Text tid='quienes-titulo'></Text></h1>
      <hr className='m-auto mt-2 w-50 ' style={{color: "black"}}/>
      <div className='m-auto border-4'>
        <div className='quienes-somos-info'>
          <Text tid='quienes-info'></Text>
        </div>
        <div className='quienes-perfiles'>
          <Perfil nombre='asun'></Perfil>
          <Perfil nombre='irene'></Perfil>
        </div>
      </div>
    </div>
  )
}
