import React from 'react';
import Text from './Text';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useMediaQuery } from 'react-responsive';

export default function Perfil(props)
{
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  return (
    <Card className='perfil'>
      <Card.Body>
        <Card.Title className='display-6'>
          <Text tid={`quienes-perfil-${props.nombre}-nombre`}></Text>
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          <Text tid={`quienes-perfil-${props.nombre}-numero`}></Text>
        </Card.Subtitle>
        <Card.Text as='div' className={isMobile ? 'w-100 text-start m-auto pb-3 pt-2':'w-75 text-start m-auto pb-3 pt-2'}>
          <Text list={true} tid={`quienes-perfil-${props.nombre}-estudios`}></Text>
        </Card.Text>
        <Button variant="primary" className='text-center m-auto' onClick = {() => window.open(`contacto`,'mywindow')}>
          <Text tid="quienes-perfil-reservar"></Text>
        </Button>
      </Card.Body>
    </Card>
  )
}
