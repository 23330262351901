import React from 'react';
import Text from './Text';
import PreguntaFrecuente from './PreguntaFrecuente';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import Button from 'react-bootstrap/Button';
import ServiciosItem from './ServiciosItem';
import Blog from './Blog';
import { useMediaQuery } from 'react-responsive';


export default function Home()
{
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isSmallLaptop = useMediaQuery({ query: '(min-width: 1000px)' })
  const isTablet = useMediaQuery({ query: '(min-width: 525px)' })


  return (
    <div>
        {isMobile 
          ?
          <div className='home-content'>
          {
            isTablet 
              ? 
              <div className="home-presen">
                <div className="text-center m-auto alieron home-title">
                  <Text tid="home-title"></Text>
                </div>
                <div className="text-center m-auto alieron home-desde">
                  <Text tid='home-presentation'></Text>
                </div>
              </div>
              :
              ''
            }
            <div className="text-center m-auto home-splide">
              <Splide 
                hasTrack={ false } 
                aria-label="Imagenes del despacho"
                  options={ {
                    rewind: true
                  } }>
                <SplideTrack>
                  <SplideSlide>
                    <img src="/img/despacho/mobile/newsofa.webp" alt="Image 1"  className='homeSplideImg'/>
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/despacho/mobile/DSCN4245.webp" alt="Image 1"  className='homeSplideImg'/>
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/despacho/mobile/DSCN4237.webp" alt="Image 1"  className='homeSplideImg'/>
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/despacho/mobile/DSCN4250.webp" alt="Image 1"  className='homeSplideImg'/>
                  </SplideSlide>
                </SplideTrack>
              </Splide>
            </div>
          <hr className='divider w-50'></hr>
          </div>
          :
          <div className='home-content'>
            <div className="text-center m-auto home-splide">
            <div>
                <div className="home-presen">
                <div>
                  <div className="text-center m-auto alieron home-title">
                    <Text tid="home-title"></Text>
                  </div>
                  <div className="text-center m-auto alieron home-desde">
                    <Text tid='home-presentation'></Text>
                  </div>
                </div>
              </div>
              {isSmallLaptop ?
                <div className='home-presen-buttonn-wrapper'>
                  <Button 
                    variant="outline-secondary"
                    className='home-presen-button'
                    onClick = {() => window.open(`quienes-somos`,'mywindow')}
                    >
                    <Text tid="home-presentation-boton1"></Text>
                  </Button>
                  <Button 
                    variant="outline-secondary" 
                    className='home-presen-button'
                    onClick = {() => window.open(`contacto`,'mywindow')}
                    >
                    <Text tid="home-presentation-boton2"></Text>
                  </Button>
                </div>
                : ''
                }
              </div>
              <Splide 
                hasTrack={ false } 
                aria-label="Imagenes del despacho"
                  options={ {
                    rewind: true
                  } }>
                <SplideTrack>
                  <SplideSlide>
                    <img src="/img/despacho/newsofa.webp" alt="Image 1"  className='homeSplideImg'/>
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/despacho/DSCN4245.webp" alt="Image 1"  className='homeSplideImg'/>
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/despacho/DSCN4237.webp" alt="Image 1"  className='homeSplideImg'/>
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/despacho/DSCN4250.webp" alt="Image 1"  className='homeSplideImg'/>
                  </SplideSlide>
                </SplideTrack>
              </Splide>
            </div>
          </div>
        }
      <div>
        <div className='servicios-title'>
          <Text tid="home-terapias"></Text>
        </div>
        <div className='servicios-main'>
          <div className='servicios-cate-wrap'>
            <div className='servicios-cate-name'><Text tid="nav-tier2-infantil"></Text></div>
            <div className='servicios-cate'>
              <ServiciosItem nombre='servicios-infantil-fracaso_escolar-title' path='infantil/fracaso-escolar'></ServiciosItem>
              <ServiciosItem nombre='servicios-infantil-problemas_de_conducta-title' path='infantil/problemas-de-conducta'></ServiciosItem>
              <ServiciosItem nombre='servicios-infantil-asesoramiento_a_padres-title' path='infantil/asesoramiento-a-padres'></ServiciosItem>
              <ServiciosItem nombre='servicios-infantil-tdah-short' path='infantil/tdah'></ServiciosItem>
              <ServiciosItem nombre='servicios-infantil-logopedia-title' path='infantil/logopedia'></ServiciosItem>
              <ServiciosItem nombre='servicios-infantil-refuerzo_educativo-title' path='infantil/refuerzo-educativo'></ServiciosItem>
            </div>
          </div>
          <div className='servicios-cate-wrap'>
            <div className='servicios-cate-name'><Text tid="nav-tier2-pareja"></Text></div>
            <div className='servicios-cate-pare'>
              <ServiciosItem nombre='servicios-pareja-main-title' path='pareja'></ServiciosItem>
            </div>
          </div>
          <div className='servicios-cate-wrap'>
            <div className='servicios-cate-name'><Text tid="nav-tier2-adultos"></Text></div>
            <div className='servicios-cate'>
              <ServiciosItem nombre='servicios-adultos-ansiedad-title' path='adultos/ansiedad'></ServiciosItem>
              <ServiciosItem nombre='servicios-adultos-estres-title' path='adultos/estres'></ServiciosItem>
              <ServiciosItem nombre='servicios-adultos-depresion-title' path='adultos/depresion'></ServiciosItem>
              <ServiciosItem nombre='servicios-adultos-adicciones_psicologicas-title' path='adultos/adicciones-psicologicas'></ServiciosItem>
              <ServiciosItem nombre='servicios-adultos-trastornos_alimentarios-title' path='adultos/trastornos-alimentarios'></ServiciosItem>
              <ServiciosItem nombre='servicios-adultos-baja_autoestima-title' path='adultos/baja-autoestima'></ServiciosItem>
              <ServiciosItem className='servicios-item' nombre='servicios-adultos-obsesiones-title' path='adultos/obsesiones'></ServiciosItem>
            </div>
          </div>
        </div>
      </div>
      <hr className='divider w-50'></hr>
      <div className='section'>
        <div className='main-margin pb-3'>
          <div className='preguntas-frecuentes-cover'>
            <Text tid="home-preguntasFrecuentes-title"></Text>
          </div>
          <div className='preguntasFrecuentes'>
            <PreguntaFrecuente tema="psicologoPsiquiatra"></PreguntaFrecuente>
            <PreguntaFrecuente tema="intervencionPsicologica"></PreguntaFrecuente>
            <PreguntaFrecuente tema="cuandoIr"></PreguntaFrecuente>
            <PreguntaFrecuente tema="eficacia"></PreguntaFrecuente>   
          </div>
        </div>
      </div>
      <hr className='divider w-75'></hr>
        <Blog></Blog>
    </div>
  )
}
