import React from 'react';
import BlogPreview from './BlogPreview';
import Pagination from '@mui/material/Pagination';

export default function Blog(props)
{
  //testing blogger URL = 2399953
  //main url = 5399110481914468774
  const bloggerURL = "https://www.googleapis.com/blogger/v3/blogs/5399110481914468774/posts?key=AIzaSyB-mshsYqAgi3UCZGbSorqW1-K1dF7R2G4";
  
  const [data, setData] = React.useState({});
  const [postNum, setPostNum] = React.useState(0);
  let [page, setPage] = React.useState(1);
  let [currentLinks, setCurrentLinks] = React.useState([]);
  const perPage = 3;

  const getData = () =>
  {
    fetch(bloggerURL)
    .then((response) => response.json())
    .then((res) => 
    {
      setData(res);
      setPostNum(res.items.length);
      setCurrentLinks(res.items.slice((page-1)*perPage, page*perPage));
    });
  }

  const handleChange = (e, p) => 
  {
    setPage(p);
    setCurrentLinks(data.items.slice((p-1)*perPage, p*perPage));
  };

  React.useEffect(() => 
  {
    getData();
  }, []);

  return (
    <div className='blog'>
      <h1 className='blog-name'>BLOG</h1>
      <div className='blog-entradas'>
        {
          currentLinks?.map((item, index) =>
            <BlogPreview key={index} post={item}></BlogPreview>
        )}
      </div>
      <Pagination 
        count={Math.ceil(postNum / perPage)} 
        onChange={handleChange}
        page={page}
        className='d-flex justify-content-center mt-3 bg-light'
        showFirstButton showLastButton
        />
    </div>
  )
}
