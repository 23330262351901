import React from 'react';
import Text from './Text';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useMediaQuery } from 'react-responsive';


export default function PreguntaFrecuente(props)
{
  const [open, setOpen]=React.useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })

  return (
  <>
    {
      isMobile ? 
      <div className={open ? 'preguntaFrecuenteOpen preguntaFrecuente' : 'preguntaFrecuente'}
        onClick={() => setOpen(!open)}
        >
        <div className='preguntaFrecuenteHead'>
          <Text tid={`home-preguntasFrecuentes-${props.tema}-question`}></Text>
        </div>
        <div className={open ? 'preguntaFrecuenteOpen preguntaFrecuenteBody' : 'preguntaFrecuenteBody'}>
          <Text tid={`home-preguntasFrecuentes-${props.tema}-content`}></Text>
        </div>
        <div className='preguntaFrecuenteMas'>
          {open ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
          
        </div>
      </div>
        :
      <div className='preguntaFrecuente'
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        >
        <div className='preguntaFrecuenteHead'>
          <Text tid={`home-preguntasFrecuentes-${props.tema}-question`}></Text>
        </div>
        <div className='preguntaFrecuenteBody'>
          <Text tid={`home-preguntasFrecuentes-${props.tema}-content`}></Text>
        </div>
        <div className='preguntaFrecuenteMas'>
          {open ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
        </div>
      </div>
    }
      </>
  )
}
