import React from "react";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import BlogPopup from './BlogPopup';
import Text from './Text';
import Chip from '@mui/material/Chip';
import { useContext } from 'react';
import { LanguageContext } from './lang/language';
import CircularProgress from '@mui/material/CircularProgress';
export default function Servicio(props)
{
  const [feedback, setFeedback] = React.useState(false);
  const [received, setReceived] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [chipLabel, setChipLabel] = React.useState(false);
  const languageContext = useContext(LanguageContext);

  let styles =
  {
    padding: "0 !important;"
  }

  const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
  const onSubmit = data => 
    {
      setSending(true);
      fetch('api/Form', 
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(
        (response) => {
          setSending(false);
          setReceived(true);
          if(response.ok)
          {
            setFeedback(true);
            setChipLabel(languageContext.dictionary["contact-chip-true"]);
            reset();
          }
          else
          {
            setFeedback(false);
            setChipLabel(languageContext.dictionary["contact-chip-false"]);
          }
        });
    }

    const handleClick = () => 
    {

    };

    const handleDelete = () => 
    {
      setReceived(false);
      reset();
    };

  return (
    <div className='servicio'>
      <Container>
        <div className='text-center alieron-ul servicio-title' style={{textTransform: ""}}>
          <Text tid={`servicios-${props.categoria}-${props.tema}-title`}></Text>
          <hr className='divider w-75 ' style={{color: "black"}}/>
        </div>
        <div className='p-2 servicio-content'>
          <img 
            className='servicio-text-img'
            style={props.left ? {float: 'left'} : {float: 'right'}}
            loading='lazy'
            src={`/img/servicios/graphic/${props.categoria}/${props.tema}.webp`}></img>
          <div className='servicio-text'>
            <Text para={true} tid={`servicios-${props.categoria}-${props.tema}-content`}></Text>
          </div>
        </div>
      </Container>
      <hr className='divider w-75'></hr>
      <div className='servicio-pregunta-title'>
        <Text tid="servicios-form-title"></Text><Text tid={`servicios-${props.categoria}-${props.tema}-title2`}></Text>?
        <br></br>
      </div>
      <div className='servicio-pregunta-subtitle'>
        <Text tid="servicios-form-subtitle"></Text>:
      </div>
      <div className='servicio-pregunta'>
        <Form onSubmit={handleSubmit(onSubmit)} className='position-relative servicio-innerForm'>
          <Form.Group className="mb-4" controlId="contactoNombre">
            <Form.Label><Text tid="contacto-form-nombre"></Text></Form.Label>
            <Form.Control 
              type="text" 
              {...register("nombre", { required: true })}
              isInvalid={errors.nombre}
            />
            <Form.Control.Feedback type="invalid">
              {errors.nombre?.type === 'required' && <p className='contanto-error'><Text tid="contacto-form-nombre-error"></Text></p>}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4" controlId="contactoApellidos">
            <Form.Label><Text tid="contacto-form-apellidos"></Text></Form.Label>
            <Form.Control 
              type="text" 
              {...register("apellidos", { required: true })}
              isInvalid={errors.apellidos}
            />
            <Form.Control.Feedback type="invalid">
              {errors.apellidos?.type === 'required' && <p className='contanto-error'><Text tid="contacto-form-apellidos-error"></Text></p>}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4" controlId="contactoEmail">
            <Form.Label><Text tid="contacto-form-email"></Text></Form.Label>
            <Form.Control 
              type="email" 
              {...register("correo", { required: true, minLength: 2 })}
              isInvalid={errors.correo}
              />
            <Form.Control.Feedback type="invalid">
              {errors.correo?.type === 'required' && <p className='contanto-error'><Text tid="contacto-form-email-error"></Text></p>}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4" controlId="contactoTelefono">
            <Form.Label><Text tid="contacto-form-tele"></Text></Form.Label>
            <Form.Control 
              type="text" 
              {...register("telefono")}
              isInvalid={errors.telefono}
              />
          </Form.Group>
          <Form.Group className="servicio-innerForm-box" controlId="contactoMotivo">
            <Form.Label><Text tid="contacto-form-consulta"></Text></Form.Label>
            <Form.Control 
              as="textarea" 
              rows={3} 
              {...register("motivo", { required: true })}
              isInvalid={errors.motivo}
              />
            <Form.Control.Feedback type="invalid">
              {errors.motivo?.type === 'required' && <p className='contanto-error'><Text tid="contacto-form-consulta-error"></Text></p>}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mt-5 servicio-innerForm-check" controlId="contactoAcepto">
            <Form.Check 
              type="checkbox" 
              {...register('acepto', { required: true })}
              isInvalid={errors.acepto}
              className='contacto-privacidad d-flex'
              label={
                <span className='d-flex'>
                  <Text tid="contacto-form-check-1"></Text>
                  {<BlogPopup terms black form title={<Text tid="footer-privacidad"></Text>} src='legal/privacidad.html'></BlogPopup>}
                  <Text tid="contacto-form-check-2"></Text>
                </span>
              }
            />
            {errors.acepto && (
              <Form.Text className="text-danger">
                {<p className='contanto-error'><Text tid="contacto-form-check-error"></Text></p>}
              </Form.Text>
            )}
          </Form.Group>

          <div className='servicioformChipContainer'>
            <CircularProgress className={sending ? "servicio-contacto-progress" : "d-none"}/>
              <Chip
                label={chipLabel}
                variant="outlined"
                onClick={handleClick}
                onDelete={handleDelete}
                style={{visibility: received ?  'visible' : 'hidden', 
                  color: feedback ? 'green' : 'red',
                  borderColor: feedback ? 'green' : 'red'
                  }}
                className='formChip'
              />
          </div>
          <Button variant="primary" type="submit" className='servicio-contacto-submit'>
            <Text tid="contacto-form-send"></Text>
          </Button>
        </Form>
      </div>
    </div>
  )
}
